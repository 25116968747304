var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_vm._m(0),_c('div',{staticClass:"mx-10 mt-3"},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"22%"}},[_c('v-select',{attrs:{"items":_vm.years,"dense":"","item-text":"name","item-value":"id","outlined":""},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_c('v-select',{staticClass:"ml-2",attrs:{"items":_vm.listMonth,"item-text":"name","item-value":"id","dense":"","outlined":""},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})],1),[_c('div',{staticClass:"d-flex mb-3"},[_c('v-btn',{staticClass:"btn-left select",style:(_vm.hasProcessed ? '' : 'backgroundColor:#D0D0D0'),attrs:{"tile":"","large":""},on:{"click":function($event){_vm.hasProcessed = false}}},[_vm._v(" 未請求 "),(
                _vm.hasProcessed === false &&
                  _vm.clientAnnualfeeListCFAnnual.length > 0
              )?_c('div',{staticClass:"message"},[_vm._v(" "+_vm._s(_vm.totalClientAnnualfeeListCFAnnual)+" ")]):_vm._e()]),_c('v-btn',{staticClass:"btn-right",style:(_vm.hasProcessed ? 'backgroundColor:#D0D0D0' : ''),attrs:{"large":"","tile":""},on:{"click":function($event){_vm.hasProcessed = true}}},[_vm._v(" 請求作成済み ")])],1)],_c('v-chip',{attrs:{"color":"red","label":"","text-color":"white","disabled":_vm.selected.length <= 0 || !_vm.checkUser},on:{"click":function($event){(_vm.visible = 3), (_vm.dialogOpen = true)}}},[_vm._v(" 選択項目を請求作成する ")]),_c('Table',{ref:"table",attrs:{"headers":_vm.headers,"total":_vm.totalClientAnnualfeeListCFAnnual,"items":_vm.clientAnnualfeeListCFAnnual,"itemsPerPage":30,"itemsPerPageOptions":[10, 20, 30, 50, 100, 200, 500],"attr":{
          dense: true,
          'no-data-text': _vm.$t('rules.noData'),
          'item-key': 'id',
          class: 'custom-table elevation-1 mt-2',
        },"showSelect":true,"funReset":_vm.reloadData,"sortField":_vm.listSortField},on:{"setSelected":function (value) {
            _vm.selected = value;
          }},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.processedAt ? '請求済み' : '未請求')+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.hasError ? 'エラー ' : ''))])]}},{key:"item.isCompany",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.isCompany ? '法人' : '個人')+" ")]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.name)+" ")]}},{key:"item.month",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.month)+"月 ")]}},{key:"item.vsAnnualFee",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.vsAnnualFee.toLocaleString('ja-JP'))+" ")]}},{key:"item.vsAnnualFeeTax",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.vsAnnualFeeTax.toLocaleString('ja-JP'))+" ")]}},{key:"item.annualFee",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.annualFee.toLocaleString('ja-JP'))+" ")]}},{key:"item.annualFeeTax",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.annualFeeTax.toLocaleString('ja-JP'))+" ")]}},{key:"item.membershipFee",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.membershipFee.toLocaleString('ja-JP'))+" ")]}},{key:"item.membershipFeeTax",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.membershipFeeTax.toLocaleString('ja-JP'))+" ")]}},{key:"item.managementFee",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.managementFee.toLocaleString('ja-JP'))+" ")]}},{key:"item.managementFeeTax",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.managementFeeTax.toLocaleString('ja-JP'))+" ")]}},{key:"item.hotspringFee",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.hotspringFee.toLocaleString('ja-JP'))+" ")]}},{key:"item.hotspringFeeTax",fn:function(ref){
          var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.hotspringFeeTax.toLocaleString('ja-JP'))+" ")]}},{key:"item.MemberDetails",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":"","to":'/customer-details/' +
                item.client.id +
                '?typeClient=' +
                item.client.type,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-account")])],1)]}},{key:"item.detail",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",class:{ 'bg--secondary': !_vm.checkUser },attrs:{"icon":"","disabled":!_vm.checkUser},on:{"click":function($event){(_vm.visible = 1), (_vm.data = item), _vm.setDataChangeMember(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.loginEmail",fn:function(ref){
                var item = ref.item;
return [(item.client.memberUser)?_c('span',[_vm._v(" "+_vm._s(item.client.memberUser.email)+" ")]):_vm._e()]}}],null,true)})],2)],1),(_vm.visible === 1)?_c('ChangeMember',{ref:"form",attrs:{"visible":_vm.visible === 1,"data":_vm.data},on:{"reloadData":_vm.reloadData,"close":function($event){_vm.visible = -1}}}):_vm._e(),_c('v-dialog',{on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('h1',{staticStyle:{"color":"#212121"}},[_vm._v("年会費請求作成確認")]),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-btn',{staticClass:"t-btn--prm btn-dialog mr-2",staticStyle:{"background-color":"#1873D9 !important"},attrs:{"width":"68"},on:{"click":_vm.closeDialog}},[_vm._v(" 閉じる ")]),_c('v-btn',{staticClass:"t-btn--red-dark btn-dialog",attrs:{"width":"220"},on:{"click":_vm.save}},[_vm._v(" チェックした"+_vm._s(_vm.selected.length)+"件を請求作成 ")])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-title',{staticClass:"mt-n5 page-title-sub card-title"},[_c('v-row',{staticStyle:{"background-color":"#f8f8f8"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":10}},[_c('h1',{staticClass:"mx-12 my-4 page-title-list",staticStyle:{"margin-left":"30px !important"}},[_vm._v(" 年会費請求 ")])])],1)],1)}]

export { render, staticRenderFns }